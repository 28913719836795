import { createContext, useContext, useEffect, useState } from "react";
import { useIdToken } from "react-firebase-hooks/auth";

import { apiRequest } from "src/apiRequest";
import { auth } from "src/firebase";

const UserContext = createContext();

export function useUser() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const [cerebraUser, setCerebraUser] = useState(null);
  const [firebaseUser, loading] = useIdToken(auth);

  useEffect(() => {
    if (firebaseUser) {
      // Fetch user permission when the user is authenticated
      apiRequest("whoami", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => setCerebraUser(data))
        .catch((err) => console.error(err));
    } else {
      // Handle the case when the user is not authenticated
      setCerebraUser(null);
    }
  }, [firebaseUser]);

  return (
    <UserContext.Provider value={{ firebaseUser, loading, cerebraUser }}>
      {children}
    </UserContext.Provider>
  );
}
