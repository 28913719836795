import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { apiRequest } from "src/apiRequest";

const SitesCreate = () => {
  const [isLoadingCompanies, setLoadingCompanies] = useState(true);
  const [companies, setCompanies] = useState([]);

  const navigate = useNavigate();

  const createNewSite = (event) => {
    event.preventDefault();

    const siteName = event.target.siteName.value;
    const siteDescription = event.target.siteDescription.value;
    const siteCompany = event.target.siteCompany.value;

    apiRequest("site/", {
      method: "POST",
      body: JSON.stringify({
        name: siteName,
        description: siteDescription,
        company_id: siteCompany,
      }),
    })
      .then((response) => {
        if (response.status === 403) {
          toast.error("You don't have permission to create a site.");
        } else if (response.ok) {
          // Handle success
          toast.success("Site Successfully created!");
          navigate("/Sites");
        } else {
          // Handle other errors
          toast.error("An error occurred while creating the site.");
        }
      })
      .catch((error) => {
        toast.error("An error occured while creating the site");
        console.error(error);
      });
  };

  const getCompanies = () => {
    apiRequest("company", {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCompanies(data);
        setLoadingCompanies(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <Stack gap={4} className="body">
      <h1>Create New Site</h1>
      <Form onSubmit={createNewSite}>
        <Row>
          <Col as="h3" xs="auto">
            Site Details
          </Col>
          <Col xs={8}>
            <Stack gap={3}>
              <Form.Group controlId="siteName">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" name="siteName" />
              </Form.Group>
              <Form.Group controlId="siteDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="siteDescription"
                  as="textarea"
                />
              </Form.Group>
              <Form.Group controlId="siteDescription">
                <Form.Label>Company</Form.Label>
                <Form.Select name="siteCompany">
                  {!isLoadingCompanies &&
                    companies.map((c) => (
                      <option value={c.id} key={c.id}>
                        {c.name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Stack>
          </Col>
        </Row>
        <hr />
        <Button variant="secondary" type="submit">
          Submit
        </Button>
      </Form>
    </Stack>
  );
};

export default SitesCreate;
