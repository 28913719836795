import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Stack, Table } from "react-bootstrap";

import { apiRequestPaginated } from "src/apiRequest";
import Pages from "src/components/Pages";

function StudiesIndex() {
  const [isLoadingStudies, setLoadingStudies] = useState(true);
  const [studies, setStudies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const itemsPerPage = 100;

  const getStudies = () => {
    apiRequestPaginated(
      "study",
      {
        method: "GET",
      },
      currentPage,
      itemsPerPage,
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.length < itemsPerPage) setIsLastPage(true);
        else setIsLastPage(false);
        setLoadingStudies(false);
        if (data.length === 0) setCurrentPage(currentPage - 1);
        else setStudies(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    getStudies();
  }, [currentPage]);

  return (
    <Stack gap={4} className="body">
      <div className="flex align-items-center justify-content-between">
        <h1>Studies</h1>
        <Button variant="outline-secondary" href="/newstudy">
          Create New Study <FontAwesomeIcon icon="plus" />
        </Button>
      </div>
      <div className="data-card">
        <Table size="sm" responsive>
          <thead>
            <tr>
              <th>Study ID</th>
              <th>Created At</th>
              <th>Description</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {!isLoadingStudies &&
              studies.map((study) => (
                <tr key={study.id}>
                  <td>{study.id}</td>
                  <td>{study.date_created}</td>
                  <td>{study.description}</td>
                  <td>
                    <Button
                      variant="outline-primary"
                      href={`/study/${study.id}`}
                    >
                      Study Details
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <Pages
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          isLastPage={isLastPage}
        />
      </div>
    </Stack>
  );
}

export default StudiesIndex;
