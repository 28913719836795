import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { apiRequest } from "src/apiRequest";

const UsersCreate = () => {
  const [sites, setSites] = useState([]);
  const [isLoadingSites, setIsLoadingSites] = useState(true);
  const navigate = useNavigate();

  const createNewUser = (event) => {
    event.preventDefault();

    const userEmail = event.target.userEmail.value;
    const userPassword = event.target.userPassword.value;
    const userSite = event.target.userSite.value;
    const userRole = event.target.userRole.value;

    if (userPassword.length < 6) {
      toast.error("Password must be at least 6 characters long");
    } else {
      apiRequest("user/", {
        method: "POST",
        body: JSON.stringify({
          email: userEmail,
          password: userPassword,
          site_id: userSite,
          role_name: userRole,
        }),
      })
        .then((response) => {
          if (response.status === 403) {
            toast.error("You don't have permission to create a user.");
          } else if (response.ok) {
            // Handle success
            toast.success("User Successfully created!");
            navigate("/Users");
          } else {
            // Handle other errors
            toast.error("An error occurred while creating the user.");
          }
        })
        .catch((error) => {
          toast.error("An error occurred while creating the user");
          console.error(error);
        });
    }
  };

  const getSites = () => {
    apiRequest("site", {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setSites(data);
        setIsLoadingSites(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    getSites();
  }, []);

  return (
    <Stack gap={4} className="body">
      <h1>Create New User</h1>
      <Form onSubmit={createNewUser}>
        <Row>
          <Col as="h3" xs="auto">
            User Details
          </Col>
          <Col xs={8}>
            <Stack gap={3}>
              <Form.Group controlId="userEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="text" name="userEmail" />
              </Form.Group>
              <Form.Group controlId="userPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="text" name="userPassword" />
              </Form.Group>
              <Form.Group controlId="userRole">
                <Form.Label>Role</Form.Label>
                <Form.Select name="userRole">
                  <option value="CompanyAdmin">Company Admin</option>
                  <option value="SiteAdmin">Site Admin</option>
                  <option value="SuperUser">Super User</option>
                  <option value="User">User</option>
                  <option value="ORPUser">ORPUser</option>
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="userSite">
                <Form.Label>Site</Form.Label>
                <Form.Select name="userSite">
                  {!isLoadingSites &&
                    sites.map((s) => (
                      <option value={s.id} key={s.id}>
                        {s.name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Stack>
          </Col>
        </Row>
        <hr />
        <Button variant="secondary" type="submit">
          Submit
        </Button>
      </Form>
    </Stack>
  );
};

export default UsersCreate;
