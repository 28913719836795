import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Stack, Table } from "react-bootstrap";
import { toast } from "react-toastify";

import { apiRequest, apiRequestPaginated } from "src/apiRequest";
import Pages from "src/components/Pages";

const ChannelMappingsIndex = () => {
  const [isLoadingChannelMappings, setLoadingChannelMappings] = useState(true);
  const [channelMappings, setChannelMappings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const itemsPerPage = 100;

  const getChannelMappings = () => {
    apiRequestPaginated(
      "channelmapping",
      {
        method: "GET",
      },
      currentPage,
      itemsPerPage,
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.length < itemsPerPage) setIsLastPage(true);
        else setIsLastPage(false);
        setLoadingChannelMappings(false);
        if (data.length === 0) setCurrentPage(currentPage - 1);
        else setChannelMappings(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const deleteChannelMapping = (id, name) => {
    apiRequest(`channelmapping/${id}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.status === 403) {
          console.log(response);
          toast.error(
            "You do not have permission to delete this Channel Mapping",
          );
        } else if (response.status === 400) {
          console.log(response);
          toast.error(
            `The Channel Mapping you have selected (${id} - ${name}) is assigned to a Scoring Run and cannot be deleted`,
          );
        } else if (response.ok) {
          toast.success("Channel Mapping successfully deleted!");
          // update list after delete
          getChannelMappings();
        } else {
          console.log(response);
          toast.error("An error occurred while deleting this channel mapping.");
        }
        return response.json();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    getChannelMappings();
  }, [currentPage]);

  return (
    <Stack gap={4} className="body">
      <div className="flex align-items-center justify-content-between">
        <h1>Channel Mappings</h1>
        <Button variant="outline-secondary" href="/newchannelmapping">
          Create New Channel Mapping <FontAwesomeIcon icon="plus" />
        </Button>{" "}
      </div>
      <div>
        <div className="data-card">
          <Table size="sm" responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Details</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {!isLoadingChannelMappings &&
                channelMappings.map((cm) => (
                  <tr key={cm.id}>
                    <td>{cm.id}</td>
                    <td>{cm.name}</td>
                    <td>
                      <Button
                        variant="outline-primary"
                        href={`/channelmapping/${cm.id}`}
                      >
                        Channel Mapping Details
                      </Button>
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        id={`delete_${cm.id}`}
                        onClick={() => deleteChannelMapping(cm.id, cm.name)}
                      >
                        <FontAwesomeIcon icon="trash" />
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Pages
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            isLastPage={isLastPage}
          />
        </div>
      </div>
    </Stack>
  );
};

export default ChannelMappingsIndex;
